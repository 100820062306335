<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select  @search="fetchOptions"
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
           :options="categories"
          
          class="select-size-lg"
       
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created () {
    this.fetchCategories()
  
  },
  props :{
    initialCategories:[]
  },


  methods:{
     updateSelections(a) {
    
    console.log(a)
  },
  fetchOptions (search, loading) {
     return new Promise((resolve, reject) => {
           var uri=`/admin/f/clientbranches?p=1&l=10&q=${search}`;
            if(search.length>3) {
        loading(true);
       
       
        axios
          .get(uri)
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
                this.categories=responseData.data;


             loading(false);
       

         

            }
            )
          .catch(error => reject(error))
     
       }
     })

     

},

         fetchCategories( ) {
   //   console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
           var uri=`/admin/f/clientbranches?p=1&l=10&q=`;
        axios
          .get(uri)
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
    //      console.log(responseData.data);
            this.categories=responseData.data;

           


         

            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
     selected: function (newVal,coldVal) {
     this.$emit("selectionChanged", newVal);

  }
},
  data() {
    return {
      categories:[],
      options: [],
      selected: this.initialCategories,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  }
   

}
</script>