import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCourses(ctx, queryParams) {
      console.log("courses Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/survey/admin/surveys', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourse(ctx, { id }) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/surveydetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateCourseInfo(ctx, { id }) {
      console.log("update course  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

   
    addSurvey(ctx, userData) {

      console.log("course Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/admin/survey', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    draftCourse(ctx, userData) {

      console.log("draftCourse Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/draft', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    publishSurvey(ctx, userData) {

      console.log("publishCourse Create triggered");
      console.log(userData);
  var id=userData.id;

   
      return new Promise((resolve, reject) => {
        axios
        .put(`/survey/admin/publishSurvey/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cloneSurvey(ctx, userData) {

      console.log("clone survey Create triggered");
      console.log(userData);
  var id=userData.id;

   
      return new Promise((resolve, reject) => {
        axios
        .put(`/survey/admin/cloneSurvey/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSurvey(ctx, userData) {

      console.log("publishCourse Create triggered");
      console.log(userData);
 var id=userData.id;
  
   
      return new Promise((resolve, reject) => {
        axios
        .put(`/survey/admin/deleteSurvey/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    completeSurvey(ctx, userData) {

      console.log("publishCourse Create triggered");
      console.log(userData);

      var id=userData.id;
      return new Promise((resolve, reject) => {
        axios
        .put(`/survey/admin/completeSurvey/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
