<template>
  <b-card>
    <!-- form -->
       <b-row>
             <b-col cols="10">
    <b-form>
     <h2 class="text-primary mt-1">Leadership Survey</h2>
      <b-row>
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localOptions.rateSingleEmployee"  name="some-radios" v-bind:value="true" >
              Rate Single Employee
            </b-form-radio>
            <b-form-radio v-model="localOptions.rateSingleEmployee"   name="some-radios" v-bind:value="false" >
              Rate Multiple Employees
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="localOptions.rateSingleEmployee" >
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing">
              <h5>Select Employee to Rate</h5>
          <member-picker class="col-12 " :initialCategories="rateSubject" @selectionChanged="selectionChanged">
          </member-picker>
          </div>
        </b-col>
      </b-row>

        <b-row v-if="!localOptions.rateSingleEmployee">
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing mb-1">
                 <b-form-radio v-model="localOptions.rateMultipleEmployeMode"     name="multiple-employees" value="MODE_GROUP" >
              Employee by Group
            </b-form-radio>
            <b-form-radio v-model="localOptions.rateMultipleEmployeMode"          name="multiple-employees" value="MODE_BRANCH" >
              Employee by Branch
            </b-form-radio>
            <b-form-radio v-model="localOptions.rateMultipleEmployeMode"        name="multiple-employees" value="MODE_DEPARTMENT" >
                  Employee by Department
            </b-form-radio>
               <b-form-radio v-model="localOptions.rateMultipleEmployeMode"         name="multiple-employees" value="MODE_POSITION" >
                  Employee by Position   
            </b-form-radio>
          </div>
        </b-col>
      </b-row>

 <b-row v-if="!(localOptions.rateSingleEmployee) && localOptions.rateMultipleEmployeMode=='MODE_GROUP'" >
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing">
              <h5>Select Group</h5>
          <group-picker class="col-12 " :initialCategories="groupSubject" @selectionChanged="selectionChanged">
          </group-picker>
          </div>
        </b-col>
      </b-row>
        <b-row v-if="!(localOptions.rateSingleEmployee) && localOptions.rateMultipleEmployeMode=='MODE_BRANCH'" >
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing">
              <h5>Select Branch</h5>
          <branch-picker class="col-12 " :initialCategories="branchSubject" @selectionChanged="selectionChanged">
          </branch-picker>
          </div>
        </b-col>
      </b-row>
       <b-row v-if="!(localOptions.rateSingleEmployee) && localOptions.rateMultipleEmployeMode=='MODE_DEPARTMENT'" >
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing">
              <h5>Select Department</h5>
          <department-picker class="col-12 " :initialCategories="departmentSubject" @selectionChanged="selectionChanged">
          </department-picker>
          </div>
        </b-col>
      </b-row>
       <b-row v-if="!(localOptions.rateSingleEmployee) && localOptions.rateMultipleEmployeMode=='MODE_POSITION'" >
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing">
              <h5>Select Position</h5>
          <position-picker class="col-12 " :multiple="Boolean(Boolean)"  :initialCategories="positionSubject" @selectionChanged="selectionChanged">
          </position-picker>
          </div>
        </b-col>
      </b-row>
     
 <b-row  v-if="!localOptions.rateSingleEmployee">
        <!-- phone -->

        <b-col  class="mt-3" cols="12">
      <h5>Select Employees to Rate</h5>

     <member-multi-picker class="col-12 " :searchOptions="searchOptions" :initialCategories="rateallSubjects" @selectionChanged="multipleEmplooyesChanged">

     </member-multi-picker>
  </b-col>
      </b-row>
  
      
      <!--/ website -->

      <b-row>
        <!-- phone -->

        <b-col  class="mt-3" cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
             </b-col>
       </b-row>

  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
      BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MemberPicker from "@/views/members/component/MemberPicker.vue"
import DepartmentPicker from "@/views/department/components/DepartmentPicker.vue"
import BranchPicker from "@/views/branch/component/BranchPicker.vue"
import GroupPicker from "@/views/clientgroups/components/GroupSelector.vue"
import PositionPicker from "@/views/position/component/PositionPicker.vue"
import MemberMultiPicker from "@/views/members/component/MemberMultiPicker.vue"

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BCardText,
    BFormRadio,
        BFormCheckbox,
        MemberPicker,DepartmentPicker,
        BranchPicker,
        GroupPicker,
        PositionPicker,
        MemberMultiPicker

  },
  directives: {
    Ripple,
  },
  props: {
    informationData:{},
     courseId: {
      type: Number,
      required: true,
    },
  },
  created (){
     
    this.localOptions=  JSON.parse(  this.informationData.additionalInfo) ;
  console.log("Create settings");
  console.log( this.localOptions);
  if(this.localOptions.rateSingleEmployee)
  {
    console.log("rate object initalizing");
    this.rateSubject=this.localOptions.rateSubject;
     console.log( this.rateSubject);
  }

  if(this.localOptions.rateallSubjects)
  {
    console.log("rate all object initalizing");
    this.rateallSubjects=this.localOptions.rateallSubjects;
     console.log( this.rateSubject);
  }


     

  
   
  },
  data() {
    return {
    
      localOptions :{
        
          rateSingleEmployee:true,
        
          rateMultipleEmployeMode:""

      } ,
      searchOptions:{
        key:null,
        keyValue:null

      },
      
      rateSubject:{},
      groupSubject:[],
      branchSubject:[],
      departmentSubject:[],
      rateallSubjects:[],
      positionSubject:[],
      isPaid: false,
      hasCertainStartDate:false ,
        hasCertainEndDate: false,
      allowPublicAccess: false,
      clevePhone: {
         numeral: true
      
      },
    };
  },
  methods: {
      selectionChanged(payload){
          console.log(payload);
          this.localOptions.rateSubject=payload;


      },
      multipleEmplooyesChanged(payload){
          console.log(payload);
          this.localOptions.rateallSubjects=payload;


      },
    sendInformation() {
      console.log(this.localOptions);
      console.log("update course  is launching with id : " + this.courseId);

      this.isLoading = true;

   

      axios({
        method: "put",
        url: `/survey/admin/surveydetail/${this.courseId}`,
        data: {
          info: "updatesurveyconfiguration",
          file: null,
          content: this.localOptions,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData.data)).additionalInfo;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
